<template>
  <div class="home m-9">
    <div class="flex flex-col items-center justify-center p-4 pl-14" v-html="content.content"></div>
    <v-footer></v-footer>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import VFooter from "@/views/components/Footer";
import Feed from '@/mixins/Feed'

export default {
  components: {VFooter},
  mixins: [Feed],
  data() {
    return {
      content: ""
    }
  },
  metaInfo:{
    title: 'Gizlilik Politikası ve Kvkk',
    meta: [
      { property: 'og:title', content:'Gizlilik Politikası ve Kvkk - Online Adam Asmaca Oyunu' },
      { property: 'description', content:"Raviosoft, iştirakleri ve iş ortakları olarak Türkiye'nin en eğlenceli Adam Asmaca Oyunu'nu bütün özellikleri ile size sunmak için oyun oynayışınız ile ilgili bazı detayları takip etmek ve entegratörlerimizle paylaşmak durumundayız. Hangi bilgilerinizin ve hareketlerinizin nasıl takip edildiğini ve kimlerle, nasıl ve hangi amaçla paylaşıldığını en günce l hali ile buradan okuyabilirsiniz." },
    ]
  },
  methods: {
    ...mapActions(["getContent"])
  },
  created() {
    this.getContent(4).then((response) => {
      this.content = response;
    })

    this.feedAllEvent('view_privacy', null, 'engagement')
  },
}
</script>
<style scoped>
tr:nth-child(4n+1), tr:nth-child(4n-1) {
  background-color: #80808069;
}
</style>